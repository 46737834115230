.Promotion {
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  background: var(--primary-color);
  width: calc(100% / 3 - 40px);

  img {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    width: 100%;
  }

  .promotion-content-wrapper {
    padding: 15px;

    .title {
      font-weight: 700;
      font-size: 1.4em;
    }
  }
}

.PromotionDetailContainer {
  .promotion-banner .banner-content {
    background-color: var(--tertiary-color);
  }

  .promotion-content {
    border-radius: var(--border-radius);
    padding: 20px;
    margin: 20px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:first-child {
        margin-top: 0;
      }
    }

    @media (min-width: 992px) {
      width: auto;
    }
  }
}
