@import './colors.css';
@import './variables.css';

.Game {
  box-shadow: var(--box-shadow);
  min-height: 100%;

  overflow: hidden;
  border-radius: 6px;

  .jackpot {
    bottom: unset;
    z-index: 2;

    span {
      line-height: 20px;
    }
  }

  .favorite-heart {
    display: none;
    bottom: 10px;
    top: initial;
    z-index: 10;
    width: 24px;
    height: 24px;

    .icon-heart-o,
    .icon-heart {
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0px;
        right: 0;
      }
    }

    .icon-heart-o {
      &:before {
        background-image: url('../images/favourited-disabled.svg');
      }
    }

    .icon-heart {
      &:before {
        background-image: url('../images/favourited-enabled.svg');
      }
    }
  }

  .thumbnail {
    z-index: 0;

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      content: '';
      background: linear-gradient(transparent 60%, var(--black) 100%);
      top: 0;
    }

    .badges {
      top: 5px;
      bottom: initial;
      flex-wrap: wrap;

      .badge {
        margin-bottom: 5px;
      }
    }

    .badges .badge,
    .ribbon {
      background: rgba(0, 25, 60, 0.88);
    }

    .ribbon + .badges {
      flex-direction: column;
    }
  }

  .game-info {
    display: flex;
    flex-flow: column-reverse;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-left: 5px;
    padding-bottom: 5px;

    .title {
      margin-bottom: 5px;
      font-weight: 800;
      order: 2;
    }

    .provider {
      font-weight: 600;
      order: 1;
    }

    &:before {
      content: '';
      display: none;
      width: 40px;
      height: 40px;
      background-image: url('../images/play-now.svg');
      box-shadow: var(--box-shadow);
      border-radius: 50%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &:hover {
    .favorite-heart {
      display: block;
    }

    .game-info {
      &:before {
        display: block;
      }
    }

    .thumbnail {
      &:after {
        background: linear-gradient(transparent 60%, var(--black) 100%),
          rgba(0, 0, 0, 0.5);
      }
    }
  }

  @media (max-width: 992px) {
    background-color: #003b56;

    .game-info {
      display: flex;
      position: relative;
      padding: 10px;
      height: auto;

      &:before {
        display: none;
      }
    }

    .favorite-heart {
      position: absolute;
      display: block;
      bottom: 5px;
    }

    .thumbnail {
      &:after {
        display: none;
      }
    }

    &:hover {
      .game-info {
        &:before {
          display: none;
        }
      }
    }
  }
}
