.successful-deposit.popup {
  .popup-content {
    h2 {
      text-transform: uppercase;
    }

    > div {
      background: none;
    }
  }
}
