.LanguageSelector {
  margin-bottom: 40px;

  &__label {
    border-radius: var(--border-radius);
    background: var(--white);
    color: black;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    font-size: 16px;
  }

  .select-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
    text-align: center;
    pointer-events: none;
    color: #2c8dc6;
    margin-bottom: 4px;

    .icon:before {
      content: '\e911';
    }
  }
}
