@import '../variables.css';

.CasinoContainer {
  .main-content-container {
    .content {
      padding-top: 20px;
    }

    .Banners {
      height: 35vh;

      @media (max-width: 992px) {
        height: 35vh;
      }

      @media (max-width: 750px) {
        height: 35vh;

        .background {
          background-position: left !important;
        }
      }

      .content {
        bottom: 0 !important;
        left: 0 !important;
        top: initial;
        right: initial;
        padding: 0 50px 30px;
        font-weight: 400;
      }
    }

    .CasinoGrid {
      margin: 20px 33px;
      padding: 0 20px;

      &:first-child {
        margin-top: 0;
      }

      &__header {
        justify-content: unset;
        align-items: center;
        margin-bottom: 10px;
        .header-and-icon {
          font-size: 1.4em;
        }

        a {
          margin: 0 20px;
        }
      }

      &__item {
        .Game {
          .game-info {
            position: absolute;
          }
        }
      }

      @media (max-width: 992px) {
        padding: 0 10px;
        margin: 0;
      }
    }
  }
}
