span.notifications-badge {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: none;
}

span.notifications-badge[data-notifications]:not([data-notifications='']):not([data-notifications='0']) {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #2c8dc6;
    font-size: 11px;
    color: #000000;
    text-align: center;
    line-height: 19px;
    font-weight: bold;
    box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  }

span.notifications-badge[data-notifications]:not([data-notifications='']):not([data-notifications='0'])::after {
      content: attr(data-notifications);
    }

span.notifications-badge--right {
    right: 0;
    left: auto;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
  }
