/* Put all brand specific css and logos/backgrounds/icons in this skin folder. */

@font-face {
  font-family: Avenir Next;
  font-style: italic;
  font-weight: 400;
  src: url('/skin/fonts/AvenirNextLTPro-It.otf') format('truetype');
}

@font-face {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  src: url('/skin/fonts/AvenirNextLTPro-Regular.otf') format('truetype');
}

@font-face {
  font-family: Avenir Next;
  font-style: italic;
  font-weight: 500;
  src: url('/skin/fonts/AvenirNextLTPro-MediumIt.otf') format('truetype');
}

@font-face {
  font-family: Avenir Next;
  src: url('/skin/fonts/AvenirNextLTPro-DemiIt.otf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Avenir Next;
  src: url('/skin/fonts/AvenirNextLTPro-HeavyIt.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Avenir Next;
  src: url('/skin/fonts/AvenirNextLTPro-Heavy.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Avenir Next;
  src: url('/skin/fonts/AvenirNextLTPro-HeavyIt.otf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Avenir Next;
  src: url('/skin/fonts/AvenirNextLTPro-Heavy.otf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

.OffCanvas,
.WelcomeBonus,
.content h3,
body,
.button,
button,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
nav.categories a,
.casino-categories-item,
.casino-categories-item-total,
.tg-input .Input input,
.tg-select .Select select,
.Promotion .content h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Avenir Next', 'Fira Sans', sans-serif;
}

button,
.primary-button,
.secondary-button,
.button,
.bonus-code-form,
.Accordion .button,
.responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button,
.responsiblegaming-signup .button {
  border-radius: 1.5em 0.5em 1.5em 0.5em;
  text-transform: uppercase;
  color: #f8f8f8;
  font-weight: 800 !important;
  font-style: italic;
}

button.primary,
  button .primary-button,
  button.secondary,
  button .secondary-button,
  button.tertiary,
  button .tertiary-button,
  .primary-button.primary,
  .primary-button .primary-button,
  .primary-button.secondary,
  .primary-button .secondary-button,
  .primary-button.tertiary,
  .primary-button .tertiary-button,
  .secondary-button.primary,
  .secondary-button .primary-button,
  .secondary-button.secondary,
  .secondary-button .secondary-button,
  .secondary-button.tertiary,
  .secondary-button .tertiary-button,
  .button.primary,
  .button .primary-button,
  .button.secondary,
  .button .secondary-button,
  .button.tertiary,
  .button .tertiary-button,
  .bonus-code-form.primary,
  .bonus-code-form .primary-button,
  .bonus-code-form.secondary,
  .bonus-code-form .secondary-button,
  .bonus-code-form.tertiary,
  .bonus-code-form .tertiary-button,
  .Accordion .button.primary,
  .Accordion .button .primary-button,
  .Accordion .button.secondary,
  .Accordion .button .secondary-button,
  .Accordion .button.tertiary,
  .Accordion .button .tertiary-button,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.primary,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .primary-button,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.secondary,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .secondary-button,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.tertiary,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .tertiary-button,
  .responsiblegaming-signup .button.primary,
  .responsiblegaming-signup .button .primary-button,
  .responsiblegaming-signup .button.secondary,
  .responsiblegaming-signup .button .secondary-button,
  .responsiblegaming-signup .button.tertiary,
  .responsiblegaming-signup .button .tertiary-button {
    background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: #f8f8f8;
    transition: none;
  }

button.primary:hover, button .primary-button:hover, button.secondary:hover, button .secondary-button:hover, button.tertiary:hover, button .tertiary-button:hover, .primary-button.primary:hover, .primary-button .primary-button:hover, .primary-button.secondary:hover, .primary-button .secondary-button:hover, .primary-button.tertiary:hover, .primary-button .tertiary-button:hover, .secondary-button.primary:hover, .secondary-button .primary-button:hover, .secondary-button.secondary:hover, .secondary-button .secondary-button:hover, .secondary-button.tertiary:hover, .secondary-button .tertiary-button:hover, .button.primary:hover, .button .primary-button:hover, .button.secondary:hover, .button .secondary-button:hover, .button.tertiary:hover, .button .tertiary-button:hover, .bonus-code-form.primary:hover, .bonus-code-form .primary-button:hover, .bonus-code-form.secondary:hover, .bonus-code-form .secondary-button:hover, .bonus-code-form.tertiary:hover, .bonus-code-form .tertiary-button:hover, .Accordion .button.primary:hover, .Accordion .button .primary-button:hover, .Accordion .button.secondary:hover, .Accordion .button .secondary-button:hover, .Accordion .button.tertiary:hover, .Accordion .button .tertiary-button:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.primary:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .primary-button:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.secondary:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .secondary-button:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.tertiary:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .tertiary-button:hover, .responsiblegaming-signup .button.primary:hover, .responsiblegaming-signup .button .primary-button:hover, .responsiblegaming-signup .button.secondary:hover, .responsiblegaming-signup .button .secondary-button:hover, .responsiblegaming-signup .button.tertiary:hover, .responsiblegaming-signup .button .tertiary-button:hover {
      background: linear-gradient(90deg, #4c899d 20%, #235066);
      color: #f8f8f8;
    }

button.selected, .primary-button.selected, .secondary-button.selected, .button.selected, .bonus-code-form.selected, .Accordion .button.selected, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.selected, .responsiblegaming-signup .button.selected {
    background: linear-gradient(90deg, #4c899d 20%, #235066) !important;
  }

button.loading svg circle, .primary-button.loading svg circle, .secondary-button.loading svg circle, .button.loading svg circle, .bonus-code-form.loading svg circle, .Accordion .button.loading svg circle, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.loading svg circle, .responsiblegaming-signup .button.loading svg circle {
        stroke: #f8f8f8 !important;
      }

input,
select {
  border: none;
  border-radius: 4px;
  background: #f8f8f8;
  color: black;
}

input::-webkit-input-placeholder, select::-webkit-input-placeholder {
    color: #474747;
  }

input::placeholder, select::placeholder {
    color: #474747;
  }

.Input label, .Select label {
    color: #000000;
  }

.Input__status-line, .Select__status-line {
    display: none;
  }

input[type='file'] {
  background: none;
  color: inherit;
}

input[type='radio'] {
  border: 2px solid #1e638e;
}

input[type='radio']:checked {
  border: 5px solid #1e638e;
  background: #f8f8f8;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 800 !important;
}

body.is-offcanvas-open #fc_frame:not(.fc-open) {
      display: none;
    }

#fc_frame:not(.fc-open) {
  display: initial;
}

@media (max-width: 768px) {

#fc_frame:not(.fc-open) {
    display: none
}
  }

#fasttrack-crm .show-message-button {
  background-color: #227aba !important;
  color: white !important;
}

.Footer {
  background-color: #03072e;
  font-style: italic;
  font-weight: 600;
}

.Footer .topSection {
    background-color: #03072e;
  }

.Footer .footer-logo {
    margin-top: 40px;
  }

.Footer .footer-logo img {
      height: 70px !important;
    }

.Footer div.licence {
    flex-wrap: wrap;
  }

.Footer .licence a {
    -webkit-filter: brightness(255);
            filter: brightness(255);
    margin: 10px;
  }

.header-wrapper {
  background: linear-gradient(#010e27, #0d3659);
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.header-wrapper .Header__background.Header__background--visible {
    display: none;
  }

.header-wrapper .Header::before {
      content: '';
      position: absolute;
      left: -10px;
      width: 160px;
      height: 100%;
      -webkit-clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
              clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
      background: rgba(248,248,248, 0.1);
      pointer-events: none;
    }

.header-wrapper .Header__logo img {
      height: 35px;
      width: auto;
    }

.header-wrapper .MembershipActions .button:not(.user-balance) {
        font-size: 1em;
        padding: 0 3em;
        height: 40px;
        line-height: 40px;
        margin: 0;
      }

.header-wrapper .MembershipActions .user-balance {
      flex-flow: row-reverse;
      padding-right: 10px !important;
      padding-left: 0 !important;
      margin: 0;
    }

.header-wrapper .MembershipActions .user-balance .icon-eye-opened,
      .header-wrapper .MembershipActions .user-balance .icon-eye-closed,
      .header-wrapper .MembershipActions .user-balance .dots {
        display: none;
      }

.header-wrapper .MembershipActions .user-balance .balance-hide {
        height: auto;
        opacity: 1;
      }

.header-wrapper .MembershipActions .user-balance .icon-plus {
        position: relative;
        width: 21px;
        height: 21px;
        color: #2c8dc6;
        background: #f8f8f8;
        border-radius: 50%;
        margin-left: 10px;
        line-height: 25px;
      }

.header-wrapper .MembershipActions .user-balance .icon-plus:before {
          position: absolute;
        }

.header-wrapper .Header__support button,
  .header-wrapper .hamburger-icon {
    background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

.header-wrapper .Header__support span {
      display: none;
    }

.header-wrapper .Header__topnav {
    height: 100%;
    display: flex;
    align-items: center;
  }

.header-wrapper .Header__topnav .top-nav-menu-item {
      text-transform: uppercase;
      font-size: 17px !important;
      font-weight: 800;
      letter-spacing: 1px;
      position: relative;
    }

.header-wrapper .Header__topnav .top-nav-menu-item.active:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 100%;
          background: #f8f8f8;
        }

.header-wrapper .user-balance {
    height: 40px;
  }

.header-wrapper > .Header .Header__support {
    margin-right: 0;
  }

.header-wrapper .Header__support a {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
    margin-right: 15px !important;
    padding: 0;
    background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
    display: block;
  }

.header-wrapper .Header__support a i:before {
        content: '';
        width: 30px;
        height: 31px;
        background-image: url('../images/support-small.svg');
        position: absolute;
        background-size: 30px 31px;
        background-repeat: no-repeat;
        top: 5px;
        left: 5px;
      }

.header-wrapper .Header__support a:hover {
      background: linear-gradient(90deg, #4c899d 20%, #235066);
      opacity: 1 !important;
    }

.header-wrapper .hamburger-icon {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    margin-left: 20px !important;
    margin-right: 10px !important;
  }

.header-wrapper .hamburger-icon img {
      height: 16px !important;
    }

.header-wrapper .hamburger-icon:hover {
      background: linear-gradient(90deg, #4c899d 20%, #235066);
    }

.OffCanvas .canvas-overlay {
    background: rgba(0, 25, 60, 0.88);
  }

.OffCanvas .canvas-content {
    background: #2c8dc6;
  }

@media (max-width: 992px) {

.OffCanvas .canvas-content {
      padding: 20px 20px 100px
  }
    }

.OffCanvas .canvas-content header {
      text-align: center;
      margin-bottom: 25px;
    }

.OffCanvas .canvas-content header h1 {
        text-transform: uppercase;
        margin: 0;
      }

.OffCanvas .canvas-content header p {
        font-weight: 600;
        margin: 0;
      }

.OffCanvas .canvas-content .canvas-header .sidebar-title {
        display: flex;
      }

.OffCanvas .canvas-content .canvas-header .canvas-header-content {
        width: 100px;
        display: block;
        text-align: center;
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }

.OffCanvas .canvas-content .canvas-header .canvas-header-content img {
          width: 100px;
        }

.OffCanvas .canvas-content .canvas-close,
    .OffCanvas .canvas-content .canvas-back {
      padding: 7px;
      border-radius: 10px;
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
      background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
      height: 40px;
      width: 40px;
      position: relative;
    }

.OffCanvas .canvas-content .canvas-close:empty, .OffCanvas .canvas-content .canvas-back:empty {
        visibility: hidden;
      }

.OffCanvas .canvas-content .canvas-close img.back-sidebar-icon, .OffCanvas .canvas-content .canvas-back img.back-sidebar-icon {
        height: 40px;
        width: 40px;
        position: absolute;
        top: 0;
        left: 0;
      }

.OffCanvas .canvas-content .canvas-close img.exit-sidebar-icon, .OffCanvas .canvas-content .canvas-back img.exit-sidebar-icon {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px;
      }

.OffCanvas .canvas-content .canvas-close:hover, .OffCanvas .canvas-content .canvas-back:hover {
        background: linear-gradient(90deg, #4c899d 20%, #235066);
      }

@media (max-width: 992px) {
  .DashboardContainer.OffCanvas.OffCanvas--direction-right {
    height: 100%;
  }
}

.StickyFooter-mobile {
  background: #f8f8f8;
  width: 295px;
  max-width: calc(100% - 20px);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: env(safe-area-inset-bottom);
  border-radius: 6px;
  margin: 15px auto;
}

.StickyFooter-mobile .stickyfooter-nav-items {
    overflow-y: inherit;
    overflow-x: inherit;
  }

.StickyFooter-mobile a {
    justify-content: center;
    font-weight: 600;
    color: #000000;
  }

.StickyFooter-mobile a .icon {
      height: 26px;
      width: 26px;
    }

.StickyFooter-mobile a img {
      pointer-events: none;
      -webkit-filter: invert(1);
              filter: invert(1);
    }

.StickyFooter-mobile a img + div {
        font-size: 10px;
      }

.StickyFooter-mobile a.exit-sidebar-icon img, .StickyFooter-mobile a.hamburger-icon img {
        height: 26px;
        width: 26px;
      }

.StickyFooter-mobile a.active {
      background: #2579ad;
      color: #f8f8f8;
    }

.StickyFooter-mobile a.active img {
        -webkit-filter: invert(0);
                filter: invert(0);
      }

.StickyFooter-mobile a.active:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

.StickyFooter-mobile a.active:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

.StickyFooter-mobile .notifications-badge--right[data-notifications]:not([data-notifications='']):not([data-notifications='0'])::after {
    top: 8px;
    right: 8px;
  }

.PlayContainer .CasinoCategoryMenu, .CasinoContainer .CasinoCategoryMenu {
    align-items: center;
  }

.PlayContainer .CasinoCategoryMenu:before, .CasinoContainer .CasinoCategoryMenu:before {
      display: none;
    }

/* Hide categories when searching */

.PlayContainer .CasinoCategoryMenu .casino-search.active + .categories-menu, .CasinoContainer .CasinoCategoryMenu .casino-search.active + .categories-menu {
      display: none;
      width: 400px;
    }

.PlayContainer .CasinoCategoryMenu .casino-search, .CasinoContainer .CasinoCategoryMenu .casino-search {
      background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      transition: none;
    }

.PlayContainer .CasinoCategoryMenu .casino-search i, .CasinoContainer .CasinoCategoryMenu .casino-search i {
        position: absolute;
        width: 40px !important;
        height: 40px !important;
        left: 10;
        padding: 10px;
        border-radius: 50%;
        background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
        box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
        z-index: 5;
      }

.PlayContainer .CasinoCategoryMenu .casino-search i:hover, .CasinoContainer .CasinoCategoryMenu .casino-search i:hover {
          background: linear-gradient(90deg, #4c899d 20%, #235066);
        }

.PlayContainer .CasinoCategoryMenu .casino-search i.icon-close:before, .CasinoContainer .CasinoCategoryMenu .casino-search i.icon-close:before {
          content: '';
          background-image: url('../images/search-close.svg');
          width: 15px;
          height: 15px;
        }

.PlayContainer .CasinoCategoryMenu .casino-search .search-input, .CasinoContainer .CasinoCategoryMenu .casino-search .search-input {
        display: flex;
        width: 100%;
      }

.PlayContainer .CasinoCategoryMenu .casino-search .search-input .Input__status-line, .CasinoContainer .CasinoCategoryMenu .casino-search .search-input .Input__status-line {
          display: none;
          margin-left: 30px;
        }

.PlayContainer .CasinoCategoryMenu .casino-search.active, .CasinoContainer .CasinoCategoryMenu .casino-search.active {
        background: #2579ad;
        box-shadow: none;
        border-radius: 4px;
        color: #f8f8f8;
        height: 36px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        width: 400px;
      }

@media (max-width: 767px) {

.PlayContainer .CasinoCategoryMenu .casino-search.active, .CasinoContainer .CasinoCategoryMenu .casino-search.active {
          width: 100%
      }
        }

.PlayContainer .CasinoCategoryMenu .casino-search.active input, .CasinoContainer .CasinoCategoryMenu .casino-search.active input {
          color: #f8f8f8;
          border-radius: 18px;
          padding-left: 60px;
        }

.PlayContainer .CasinoCategoryMenu .casino-search.active input::-webkit-input-placeholder, .CasinoContainer .CasinoCategoryMenu .casino-search.active input::-webkit-input-placeholder {
            color: #f8f8f8;
          }

.PlayContainer .CasinoCategoryMenu .casino-search.active input::placeholder, .CasinoContainer .CasinoCategoryMenu .casino-search.active input::placeholder {
            color: #f8f8f8;
          }

.PlayContainer .CasinoCategoryMenu .categories-menu .category-item, .CasinoContainer .CasinoCategoryMenu .categories-menu .category-item {
        color: #f8f8f8;
        background: none;
        height: auto;
        border-radius: 0;
        font-weight: 600;
        padding: 2px 4px;
        border-bottom: 2px solid transparent;
      }

.PlayContainer .CasinoCategoryMenu .categories-menu .category-item:hover, .CasinoContainer .CasinoCategoryMenu .categories-menu .category-item:hover {
          background: none;
        }

.PlayContainer .CasinoCategoryMenu .categories-menu .category-item.active, .CasinoContainer .CasinoCategoryMenu .categories-menu .category-item.active {
          background: unset;
          color: #f8f8f8;
          border-bottom: 2px solid #f8f8f8;
          font-weight: 600;
        }

.PlayContainer .CasinoCategoryMenu .categories-menu .category-item a, .CasinoContainer .CasinoCategoryMenu .categories-menu .category-item a {
          text-decoration: underline;
        }

.Game {
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
  min-height: 100%;

  overflow: hidden;
  border-radius: 6px;
}

.Game .jackpot {
    bottom: unset;
    z-index: 2;
  }

.Game .jackpot span {
      line-height: 20px;
    }

.Game .favorite-heart {
    display: none;
    bottom: 10px;
    top: initial;
    z-index: 10;
    width: 24px;
    height: 24px;
  }

.Game .favorite-heart .icon-heart-o:before, .Game .favorite-heart .icon-heart:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0px;
        right: 0;
      }

.Game .favorite-heart .icon-heart-o:before {
        background-image: url('../images/favourited-disabled.svg');
      }

.Game .favorite-heart .icon-heart:before {
        background-image: url('../images/favourited-enabled.svg');
      }

.Game .thumbnail {
    z-index: 0;
  }

.Game .thumbnail:after {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      content: '';
      background: linear-gradient(transparent 60%, #000000 100%);
      top: 0;
    }

.Game .thumbnail .badges {
      top: 5px;
      bottom: initial;
      flex-wrap: wrap;
    }

.Game .thumbnail .badges .badge {
        margin-bottom: 5px;
      }

.Game .thumbnail .badges .badge,
    .Game .thumbnail .ribbon {
      background: rgba(0, 25, 60, 0.88);
    }

.Game .thumbnail .ribbon + .badges {
      flex-direction: column;
    }

.Game .game-info {
    display: flex;
    flex-flow: column-reverse;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-left: 5px;
    padding-bottom: 5px;
  }

.Game .game-info .title {
      margin-bottom: 5px;
      font-weight: 800;
      order: 2;
    }

.Game .game-info .provider {
      font-weight: 600;
      order: 1;
    }

.Game .game-info:before {
      content: '';
      display: none;
      width: 40px;
      height: 40px;
      background-image: url('../images/play-now.svg');
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
    }

.Game:hover .favorite-heart {
      display: block;
    }

.Game:hover .game-info:before {
        display: block;
      }

.Game:hover .thumbnail:after {
        background: linear-gradient(transparent 60%, #000000 100%),
          rgba(0, 0, 0, 0.5);
      }

@media (max-width: 992px) {

.Game {
    background-color: #003b56
}

    .Game .game-info {
      display: flex;
      position: relative;
      padding: 10px;
      height: auto;
    }

      .Game .game-info:before {
        display: none;
      }

    .Game .favorite-heart {
      position: absolute;
      display: block;
      bottom: 5px;
    }
      .Game .thumbnail:after {
        display: none;
      }
        .Game:hover .game-info:before {
          display: none;
        }
  }

.Loader .loader-icon {
    width: 200px;
    background-image: url('../images/logo.svg');
    background-size: 200px auto;
  }

.Loader .loading-spinner {
    margin: 20px auto 0;
  }

.CasinoContainer .header-and-icon > span, .JackpotDropContainer .header-and-icon > span {
      font-weight: 800 !important;
    }

.CasinoContainer .grid-header a, .JackpotDropContainer .grid-header a {
      font-weight: 600;
      text-decoration: underline;
    }

.CasinoContainer .GameGrid:not(.search-grid) .CasinoCategoryRow .thumbnail, .JackpotDropContainer .GameGrid:not(.search-grid) .CasinoCategoryRow .thumbnail {
        border-radius: 6px;
      }

.CasinoContainer .GameGrid:not(.search-grid) .slick-arrow.slick-next, .JackpotDropContainer .GameGrid:not(.search-grid) .slick-arrow.slick-next {
        background: rgba(0, 25, 60, 0.88);
      }

.CasinoContainer .GameGrid:not(.search-grid) .slick-arrow.slick-next:before, .JackpotDropContainer .GameGrid:not(.search-grid) .slick-arrow.slick-next:before {
          content: '';
          position: absolute;
          height: 17px;
          width: 9px;
          background-image: url('../images/menu-arrow.svg');
        }

.CasinoContainer .GameGrid:not(.search-grid) .slick-arrow.slick-prev, .JackpotDropContainer .GameGrid:not(.search-grid) .slick-arrow.slick-prev {
        background: rgba(0, 25, 60, 0.88);
      }

.CasinoContainer .GameGrid:not(.search-grid) .slick-arrow.slick-prev:before, .JackpotDropContainer .GameGrid:not(.search-grid) .slick-arrow.slick-prev:before {
          content: '';
          position: absolute;
          height: 17px;
          width: 9px;
          background-image: url('../images/menu-arrow.svg');
          -webkit-transform: rotateY(180deg);
                  transform: rotateY(180deg);
        }

.CasinoContainer .GameGrid:not(.search-grid) .slider .slide-item, .JackpotDropContainer .GameGrid:not(.search-grid) .slider .slide-item {
        font-size: 0.8em;
      }

.CasinoContainer .GameGrid:not(.search-grid) .slider .arrow--next, .JackpotDropContainer .GameGrid:not(.search-grid) .slider .arrow--next {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

.CasinoContainer .GameGrid:not(.search-grid) .slider .arrow--prev, .JackpotDropContainer .GameGrid:not(.search-grid) .slider .arrow--prev {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

.PlayContainer {
  background-color: #03072e;
  background-image: url('../images/start-page-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

.GamePlay {
  background: transparent;
}

.GamePlay .size-wrap {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

.GamePlay .home-button {
    background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    height: 40px;
    width: 40px;
    position: relative;
  }

.GamePlay .home-button:hover {
      background: linear-gradient(90deg, #4c899d 20%, #235066);
    }

.GamePlay .home-button i.icon-home:before {
        content: '';
        background-image: url('../images/home-icon.svg');
        background-size: 28px 28px;
        background-repeat: no-repeat;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 5px;
        left: 5.5px;
      }

.GamePlay .QuickDeposit form .tg-select .Select select {
        padding-right: calc(20px + var(--trailing-lane-width));
      }

@media (max-width: 992px) {

.GamePlay .QuickDeposit form .tg-select .Select select {
          padding: 6px
      }
        }

.GamePlay .favorite-btn {
    width: 24px;
    height: 24px;
    position: relative;
  }

.GamePlay .favorite-btn .icon-heart-o:before, .GamePlay .favorite-btn .icon-heart:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: -3.5px;
        right: 0;
      }

.GamePlay .favorite-btn .icon-heart-o:before {
        background-image: url('../images/favourited-disabled.svg');
      }

.GamePlay .favorite-btn .icon-heart:before {
        background-image: url('../images/favourited-enabled.svg');
      }

.game-history-wrapper {
  background-color: transparent;
  font-style: italic;
}

.game-history-wrapper .game-history-logo {
    -webkit-filter: none;
            filter: none;
    height: 45px;
    margin: 0 20px;
  }

.game-history-wrapper .dim-btn {
    width: 24px;
  }

.game-history-wrapper .dim-btn.on .lightbulb-fill {
      fill: #2c8dc6;
    }

.game-history-wrapper .game-history-chat {
    color: #2c8dc6;
    stroke: #2c8dc6;
    margin-right: 10px;
  }

.verify-account-wrapper .kyc-error,
  .verify-account-wrapper .kyc-success {
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
    background: #2579ad;
  }

.verify-account-wrapper .verify-profile {
    background: #2579ad;
    padding: 10px;
    border-radius: 4px;
  }

.verify-account-wrapper .verify-profile .error-message {
      font-weight: 500;
      font-style: normal;
      text-align: center;
    }

.verify-account-wrapper .verify-profile .select-type-input {
      width: 100% !important;
    }

.verify-account-wrapper .verify-profile .upload-description {
      text-align: left;
    }

.verify-account-wrapper .verify-profile .verify-profile-instruction-wrapper {
      padding-bottom: 0;
    }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper {
      flex-flow: column;
      text-align: center;
    }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper .default-upload {
        display: none;
      }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper .custom-upload {
        border-radius: 4px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;
      }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper .custom-upload .selected-file {
          height: 42px;
          background: #1e638e;
          font-weight: 500;
          width: 100%;
          color: #5aa2bb;
          display: inline-block;
          padding: 10px 0 10px 0 !important;
          border-radius: 0 0 4px 4px;
        }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper .custom-upload .choose-file {
          background: #1e638e !important;
          border-radius: 4px 4px 0 0;
          width: 100%;
          font-weight: 800 !important;
          background: none;
        }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper .kyc-upload-button {
        margin-bottom: 20px;
      }

.verify-account-wrapper .tg-list .row {
      margin-top: 5px;
      border-radius: 4px;
      padding: 10px;
      background: #2579ad;
    }

.PayNPlay.contact, .PayNPlay.bonus {
    max-width: 350px;
  }

.PayNPlay .Input__trailing-lane, .PayNPlay .Select__trailing-lane {
      display: none;
    }

.PayNPlay .Input__label, .PayNPlay .Select__label {
      font-weight: bold;
      -webkit-transform: none;
              transform: none;
      color: #f8f8f8;
    }

.PayNPlay .Input--has-value .Input__label, .PayNPlay .Select--has-value .Input__label {
        display: none;
      }

.PayNPlay .Select__label {
      display: none;
    }

.PayNPlay .tg-input__status-text, .PayNPlay .tg-phonenumber-input__status-text {
      font-weight: 600;
    }

.PayNPlay input,
  .PayNPlay select {
    font-family: Avenir Next;
    font-weight: bold;
    background: none;
    color: #f8f8f8;
    border-bottom: 2px solid #f8f8f8;
    caret-color: #f8f8f8;
    border-radius: 0;
    padding: 0;
  }

.PayNPlay input::-webkit-input-placeholder, .PayNPlay select::-webkit-input-placeholder {
      color: #f8f8f8 !important;
    }

.PayNPlay input::placeholder, .PayNPlay select::placeholder {
      color: #f8f8f8 !important;
    }

.PayNPlay .Amount__custom-amount input {
        font-size: 3.5em !important;
        line-height: 1em;
        text-align: center;
      }

.PayNPlay .Amount__question button {
        line-height: inherit;
        background: none;
        box-shadow: none;
        font-size: inherit;
        font-weight: inherit !important;
        text-transform: unset;
        padding: 0;
        text-decoration: underline;
        font-style: italic;
      }

.PayNPlay .Amount__question button:hover {
          background: none;
        }

.PayNPlay .Contact .Input__label {
        left: 45px;
        top: 20px;
      }

.PayNPlay .Contact input[type='email'],
    .PayNPlay .Contact input[type='tel'] {
      background-repeat: no-repeat;
      padding-left: 45px;
      background-size: 35px 35px;
    }

.PayNPlay .Contact input[type='email'] {
      background-image: url('../images/input-email.svg');
      background-position-y: 10px;
    }

.PayNPlay .Contact input[type='tel'] {
      background-image: url('../images/input-phone.svg');
      background-position-y: center;
    }

.PayNPlay .Bonus .BonusSelector {
      margin-bottom: var(--pnp-spacing);
    }

.PayNPlay .Bonus .BonusSelector h3 {
        margin: 45px 0 5px 0;
      }

.PayNPlay .Bonus .BonusSelector__bonus {
        position: relative;
        padding: 15px;
        border-radius: 10px;
        margin-top: 50px;
        background-color: #0182be;
      }

.PayNPlay .Bonus .BonusSelector__bonus__content {
          text-align: left;
        }

.PayNPlay .Bonus .BonusSelector__bonus__content * {
            margin: 0;
          }

.PayNPlay .Bonus .BonusSelector__bonus__content img {
            border-radius: 10px;
            width: 80px;
            height: 80px;
            object-position: center;
            object-fit: cover;
            position: absolute;
            border: 2px solid #ffffff;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: -30px;
          }

.PayNPlay .Bonus .BonusSelector__bonus button {
          margin-top: var(--pnp-spacing);
        }

.BonusSelector {
  max-width: 320px;
  width: 100%;
}

.BonusSelector h3 {
    margin: 45px 0 5px 0;
  }

@media (max-width: 992px) {

.BonusSelector {
    max-width: none
}
  }

.BonusSelector__bonus {
    position: relative;
    padding: 15px;
    border-radius: 10px;
    margin-top: 50px;
  }

.BonusSelector__bonus--selected {
      background-color: #0182BE;
    }

.BonusSelector__bonus-description {
    text-align: left;
  }

.BonusSelector__bonus-description * {
      margin: 0;
    }

.BonusSelector__bonus-description img {
      border-radius: 10px;
      width: 80px;
      height: 80px;
      object-position: center;
      object-fit: cover;
      position: absolute;
      border: 2px solid #ffffff;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: -30px;
    }

.reality-check {
  color: #f8f8f8;
  text-align: center;
}

.reality-check .header {
    background: #2579ad !important;
    justify-content: center;
    width: 100% !important;
  }

.Popup__header {
    color: #f8f8f8;
  }

.Popup__content {
    overflow: auto;
    font-style: italic;
    color: #000000;
    border-radius: 4px;
  }

.Popup__children .RealityCheckContainer .actions .button:last-child {
            width: 100%;
            margin: 15px 0;
          }

.successful-deposit.popup .popup-content h2 {
      text-transform: uppercase;
    }

.successful-deposit.popup .popup-content > div {
      background: none;
    }

.ContentPreviewContainer form {
    display: flex;
    align-items: center;
  }

@media (max-width: 900px) {

.ContentPreviewContainer form {
      flex-direction: column;
      align-items: unset
  }
    }

.casino-promotion .casino-promotion-image,
  .casino-promotion .casino-promotion-video video {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }

.casino-promotion .stats-wrapper,
  .casino-promotion .summary p {
    font-weight: 600;
  }

.casino-promotion .summary img {
      height: 100px;
    }

.casino-promotion .actions.button.primary {
    background: none;
    box-shadow: none;
    border-radius: 0;
    font-size: 1.5em;
    padding: 0;
  }

.casino-promotion .actions.button.primary:before {
      content: '';
      background-image: url('../images/play-now.svg');
      width: 40px;
      height: 40px;
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      margin-right: 15px;
    }

.casino-promotion .casino-promotion-bg::before {
      background: linear-gradient(
        to bottom,
        #03072e,
        transparent
      );
    }

.casino-promotion .casino-promotion-bg::after {
      background: linear-gradient(to top, #03072e, transparent);
    }

@media (max-width: 992px) {
    .casino-promotion .stats-wrapper {
      display: none;
    }

    .casino-promotion .summary p,
    .casino-promotion a.actions {
      margin: 0;
    }

    .casino-promotion .casino-promotion-content {
      flex-direction: column-reverse;
    }
  }

.rg-gaming-content .popup-content {
    background: #2c8dc6 !important;
    text-align: center;
  }

.rg-gaming-content .popup-content > div {
      min-height: unset;
      width: 100% !important;
    }

.rg-gaming-content .popup-content .button-wrapper {
      display: block;
    }

.SessionSummary .Summary .date {
      color: #f8f8f8 !important;
      font-weight: 400;
    }

.SessionSummary .Summary .info {
      font-weight: bold;
    }

.SessionSummary .Summary .info span:nth-child(2) {
        color: #f8f8f8 !important;
        font-weight: 400;
      }

.SessionSummary .ResponsibleGaming a {
      color: #f8f8f8 !important;
    }

.rg-wrapper {
  font-style: italic;
}

.rg-wrapper .SessionClock {
    position: relative;
    padding-top: 3px;
  }

.rg-wrapper .SessionClock i.icon-live-sports {
      margin-right: 18px;
    }

.rg-wrapper .SessionClock i.icon-live-sports:before {
        content: '';
        background-image: url('../images/clock.svg');
        width: 14px;
        height: 14px;
        position: absolute;
        top: 4.5px;
      }

.AccordionWidget .AccordionItem {
    margin-top: 1px;
    border-radius: 0;
  }

.AccordionWidget .AccordionItem:first-child {
      border-radius: 4px 4px 0 0;
    }

.AccordionWidget .AccordionItem:last-child {
      border-radius: 0 0 4px 4px;
    }

.AccordionWidget .AccordionItem:only-child {
      border-radius: 4px;
    }

.AccordionWidget .AccordionItem .AccordionItem__header {
      background-color: #2579ad;
    }

.AccordionWidget .AccordionItem .AccordionItem__header:hover {
        background-color: #2579ad;
      }

.AccordionWidget .AccordionItem .AccordionItem__header .responsiblegaming-item-header .info-amount,
      .AccordionWidget .AccordionItem .AccordionItem__header .responsiblegaming-item-header .title-text,
      .AccordionWidget .AccordionItem .AccordionItem__header .AccordionItem__expand-icon {
        color: #f8f8f8;
      }

.AccordionWidget .AccordionItem .AccordionItem__content {
      background-color: #2579ad;
    }

.AccordionWidget .AccordionItem .AccordionItem__content .navigation {
        display: flex;
        justify-content: space-between;
      }

.AccordionWidget .AccordionItem__content > div {
        padding: 8px 15px 20px;
      }

.AccordionWidget .AccordionItem__content > div form .ResponsibleGamingWidget__timespan {
            margin: 0 0 25px 0;
          }

.AccordionWidget .AccordionItem__content > div form .ResponsibleGamingWidget__timespan-limit-selector .tg-input {
              margin: 0 !important;
            }

.AccordionWidget .AccordionItem__content > div form .ResponsibleGamingWidget__timespan-limit-selector .tg-input .Input input {
                  font-size: 1rem;
                  caret-color: #000000;
                  font-weight: bold;
                  padding-bottom: 8px;
                }

.AccordionWidget .AccordionItem__content > div form .ResponsibleGamingWidget__timespan-limit-selector .button {
              width: auto;
              padding: 0 2.5em;
              display: grid;
              place-items: center;
            }

.responsiblegaming-signup .AmountItem, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .AmountItem {
    margin: 10px;
  }

.responsiblegaming-signup .custom-amount-increment,
  .responsiblegaming-signup .custom-amount-decrement,
  .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-increment,
  .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-decrement {
    color: #f8f8f8 !important;
    background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%) !important;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }

.responsiblegaming-signup .custom-amount-increment:hover, .responsiblegaming-signup .custom-amount-decrement:hover, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-increment:hover, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-decrement:hover {
      background: linear-gradient(90deg, #4c899d 20%, #235066) !important;
    }

.responsiblegaming-signup .custom-amount-input .Input input[type='number'], .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-input .Input input[type='number'] {
      padding-top: 13px;
      background: #f8f8f8 !important;
      color: black !important;
      caret-color: #2c8dc6;
    }

.responsiblegaming-signup .custom-amount-input .Input input[type='number']::-webkit-input-placeholder, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-input .Input input[type='number']::-webkit-input-placeholder {
        color: #474747 !important;
      }

.responsiblegaming-signup .custom-amount-input .Input input[type='number']::placeholder, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-input .Input input[type='number']::placeholder {
        color: #474747 !important;
      }

.responsiblegaming-signup .limit-info-container, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .limit-info-container {
    text-align: center;
    text-transform: uppercase;
  }

.responsiblegaming-signup .custom-amount .error-message span, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount .error-message span {
      color: black;
    }

.responsiblegaming-signup .timespan-label, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .timespan-label {
    color: #f8f8f8;
    font-weight: bold;
  }

.responsiblegaming-signup .timespan-selector .button, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .timespan-selector .button {
      background: none !important;
      box-shadow: none !important;
      font-weight: normal !important;
      font-size: 14px !important;
      padding: 2px;
    }

.responsiblegaming-signup .timespan-selector .button.selected, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .timespan-selector .button.selected {
        background: none !important;
        text-decoration: underline;
      }

.responsiblegaming-signup .timespan-selector .button:hover, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .timespan-selector .button:hover {
        text-decoration: underline;
      }

@media (max-width: 540px) {

.responsiblegaming-signup .timespan-selector .button, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .timespan-selector .button {
        font-size: 12px !important
    }
      }

.pending-container {
  padding: 20px;
}

.pending-container button {
    margin-top: 10px;
  }

.ErrorBoundary {
  padding: 0;
}

.ErrorBoundary .error-message-container {
    text-align: center;
    border-radius: 4px;
  }

.ErrorBoundary .error-message-container h1,
    .ErrorBoundary .error-message-container p {
      color: #f8f8f8;
    }

.notification {
  background: #2579ad;
  border-radius: 0 0 4px 4px;
}

.notification .timeout-bar {
    background: #f8f8f8;
  }

.notification .content .head .date {
      margin-top: 2px;
    }

.notification .notification-buttons .notification-button {
      color: #f8f8f8;
      border-top: 1px solid #f8f8f8;
    }

.notification .notification-buttons .notification-button:hover {
        color: rgba(248,248,248, 0.8);
      }

.notifications-badge,
.notifications-badge--right {
  background-color: #56a0ba !important;
  color: #f8f8f8 !important;
  width: 24px !important;
  height: 24px !important;
  line-height: 25px !important;
}

.DashboardContainer
  .canvas-content
  .Dashboard
  .profile-links
  .ItemWithIconAndText
  .notifications-badge--right {
  box-shadow: none;
  top: 16px;
  right: 18px;
}

.messages-list-item {
  background-color: #2579ad;
  border-radius: 4px;
}

.messages-list-item:not(.open).messages-list-item:hover {
    background-color: #1e638e;
  }

.messages-list-item:not(.open).messages-list-item:hover .message-wrapper::after {
      background: linear-gradient(transparent, #1e638e);
    }

.messages-list-item .message-wrapper::after {
    background: linear-gradient(transparent, #1e638e);
  }

.messages-list-item header {
  margin-bottom: 0px !important;
}

.messages-list-item header .title::after {
      background-color: #f8f8f8;
      top: 9px;
    }

.messages-list-item header .time {
    color: #f8f8f8;
  }

.tg-list .row.active .detail {
        background: #2579ad;
        justify-content: space-evenly;
      }

.tg-list .row.active .detail:before {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #2579ad;
        }

.tg-list .row .info:before {
        background: #5bc2ff;
      }

.Tournament {
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.Tournament img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

.TournamentDetailContainer .tournament-banner .banner-content {
    background-color: #1e638e;
  }

.TournamentDetailContainer .tournament-content .column:first-child {
      padding: 20px;
      margin: 20px;
    }

.TournamentDetailContainer .tournament-content .column:first-child h1:first-child, .TournamentDetailContainer .tournament-content .column:first-child h2:first-child, .TournamentDetailContainer .tournament-content .column:first-child h3:first-child, .TournamentDetailContainer .tournament-content .column:first-child h4:first-child, .TournamentDetailContainer .tournament-content .column:first-child h5:first-child, .TournamentDetailContainer .tournament-content .column:first-child h6:first-child {
          margin-top: 0;
        }

.TournamentDetailContainer .tournament-content .leaderboard {
      border-radius: 4px;
    }

.TournamentDetailContainer .tournament-content .leaderboard-header ~ .leaderboard {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

.TournamentDetailContainer .tournament-content .leaderboard-header {
      background-color: #1e638e;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding-bottom: 20px;
      font-weight: bold;
    }

.TournamentDetailContainer .tournament-join .button {
      transition: none;
    }

.Promotion {
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  background: #2c8dc6;
  width: calc(100% / 3 - 40px);
}

.Promotion img {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    width: 100%;
  }

.Promotion .promotion-content-wrapper {
    padding: 15px;
  }

.Promotion .promotion-content-wrapper .title {
      font-weight: 700;
      font-size: 1.4em;
    }

.PromotionDetailContainer .promotion-banner .banner-content {
    background-color: #1e638e;
  }

.PromotionDetailContainer .promotion-content {
    border-radius: 4px;
    padding: 20px;
    margin: 20px;
  }

.PromotionDetailContainer .promotion-content h1:first-child, .PromotionDetailContainer .promotion-content h2:first-child, .PromotionDetailContainer .promotion-content h3:first-child, .PromotionDetailContainer .promotion-content h4:first-child, .PromotionDetailContainer .promotion-content h5:first-child, .PromotionDetailContainer .promotion-content h6:first-child {
        margin-top: 0;
      }

@media (min-width: 992px) {

.PromotionDetailContainer .promotion-content {
      width: auto
  }
    }

.CasinoCategoryRow .overlay {
    display: none;
  }

.CasinoCategoryRow .category-name {
    display: none;
  }

.JackpotDropContainer main > header {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    text-transform: uppercase;
  }

@media (min-width: 993px) {

.JackpotDropContainer main > header {
      padding-left: 20px;
      padding-right: 20px
  }
    }

.JackpotDropContainer .JackpotWidget {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

.JackpotDropContainer .JackpotWidget__badge {
      font-weight: bold;
      border-radius: 2px;
      text-shadow: initial;
    }

.JackpotDropContainer .JackpotWidget__info::before {
        content: '';
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        margin-right: 20px;
      }

.JackpotDropContainer .JackpotWidget__info--clock::before {
        background-image: url('../images/clock-icon.svg');
      }

.JackpotDropContainer .JackpotWidget__info--jackpot::before {
        background-image: url('../images/diamond-icon.svg');
      }

body {
  --gameplay-topbar-height: 0px;
  --gameplay-header-height: 56px;
  --gameplay-bottombar-height: 0px;
  --gameplay-frame-darkmode-bgcolor: #000000;
  --gameplay-popunder-overlay-bgcolor: rgba(0, 25, 60, 0.88);
  --gameplay-header-bgcolor: #2c8dc6;
}

body.is-desktop {
    --gameplay-header-height: 60px;
    --gameplay-bottombar-height: 75px;
  }

body.jurisdiction-sga {
    --gameplay-topbar-height: 30px;
  }

.PlayContainer .button.icon {
    box-shadow: none;
  }

.PlayContainer .GamePlay .GamePlayHeader__back .button, .PlayContainer .GamePlay .GamePlayHeader__quick-deposit .button {
          background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%) !important;
          box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
        }

.PlayContainer .GamePlay .GamePlayHeader__menu,
      .PlayContainer .GamePlay .GamePlayHeader__menu-close {
        background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
        box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
      }

.PlayContainer .GamePlay .GamePlayHeader__hamburger a {
          padding: 5px;
        }

.PlayContainer .GamePlay .GamePlayHeader__hamburger img {
          margin-top: 7px;
          height: 15px;
        }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit form .tg-input .Input input {
          height: 37px;
        }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit form .tg-select .Select select {
          padding-right: calc(15px + var(--trailing-lane-width));
        }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit .QuickDepositMobile {
          display: flex;
          color: #000000;
          border-radius: 5px;
        }

.PlayContainer .GamePlay .GamePlayHeader__user .button {
          display: none;
        }

.PlayContainer .GamePlay .GamePlayHeader__name {
        display: flex;
        margin-right: 8px;
      }

.PlayContainer .GamePlay .GamePlayHeader .MembershipActions .button:first-child {
          margin-right: 12px;
          margin-left: 30px;
        }

.PlayContainer .GamePlay .GamePlayHeader .MembershipActions .button:first-child::before {
            left: -33px;
          }

.PlayContainer .GamePlay .GameDetails {
      background-color: #2c8dc6;
      color: #f8f8f8;
    }

.PlayContainer .GamePlay .GameDetails__fav {
        background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
        color: #f8f8f8;
      }

.PlayContainer .GamePlay .GameDetails__quick-actions button {
          background: #f8f8f8;
          color: #000000;
          border-radius: 50%;
        }

.PlayContainer .GamePlay .GameDetails__categories-nav {
        margin-bottom: 10px;
      }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .casino-search {
            margin-left: 0;
          }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .categories-menu::after {
              background: none !important;
            }

@media (max-width: 992px) {

.PlayContainer .GamePlay .GameDetails__category {
          margin-bottom: 50px
      }
          .PlayContainer .GamePlay .GameDetails__category .NoGamesMessage {
            color: #f8f8f8;
            padding: 30px;
          }
        }

.PlayContainer .GamePlay .GameDetails__category .GameGrid .slider .last-slide a:last-child {
              background: #f8f8f8;
              color: #2c8dc6;
            }

.PlayContainer .GamePlay .GameDetails .slider .arrow--next {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

.PlayContainer .GamePlay .GameDetails .slider .arrow--prev {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }

.PlayContainer .GamePlay .GameFrame__footer {
        display: none;
      }

.QuickDepositContainer .popup-content i {
      display: none;
    }

.LanguageSelector {
  margin-bottom: 40px;
}

.LanguageSelector__label {
    border-radius: 4px;
    background: #f8f8f8;
    color: black;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    font-size: 16px;
  }

.LanguageSelector .select-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
    text-align: center;
    pointer-events: none;
    color: #2c8dc6;
    margin-bottom: 4px;
  }

.LanguageSelector .select-icon .icon:before {
      content: '\e911';
    }

.IndexContainerPnp .fixed-position {
    margin-top: 10px;
    z-index: 1;
  }

.IndexContainerPnp .fixed-position .actions .button {
        padding: 0;
        width: 370px;
      }

@media (max-width: 530px) {

.IndexContainerPnp .fixed-position .actions .button {
          width: 90%
      }
        }

.IndexContainerPnp .index-background-active + .fixed-position .Carousel,
    .IndexContainerPnp .index-background-active + .fixed-position .Features,
    .IndexContainerPnp .index-background-active + .fixed-position .brand-description h2 {
      display: none;
    }

.IndexContainerPnp .PayNPlayContainer {
    margin-top: 100px;
  }

@media (max-width: 992px) {

.IndexContainerPnp .PayNPlayContainer {
      margin-top: 80px
  }
    }

.IndexContainerPnp .brand-description + .PayNPlayContainer {
    margin-top: 0;
  }

.IndexContainerPnp .Features .feature, .IndexContainerPnp .Carousel .feature {
      width: 250px;
    }

.IndexContainerPnp .Features .feature img, .IndexContainerPnp .Carousel .feature img {
        height: 70px;
      }

.IndexContainerPnp .Features .feature .feature-content > :first-child, .IndexContainerPnp .Carousel .feature .feature-content > :first-child {
          margin: 15px 4px 4px 4px;
          text-transform: uppercase;
        }

.IndexContainerPnp .Features .feature .feature-content p, .IndexContainerPnp .Carousel .feature .feature-content p {
          margin: 0;
          padding: 0 40px;
        }

.IndexContainerPnp .main-content-container {
    background-image: none;
    background-size: auto;
    background-repeat: repeat-x;
  }

.IndexContainerPnp .main-content-container .index__header .index__header__logo {
        display: none;
        background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
        box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        width: 40px;
        height: 40px;
        position: relative;
      }

.IndexContainerPnp .main-content-container .index__header .index__header__logo::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 18px;
          height: 18px;
          background: url('../images/cancel-white.svg') no-repeat center;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__logo img {
          display: none;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__home,
        .IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu {
          background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
          box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 10px;
          height: 40px;
          width: 40px;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__home img, .IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu img {
            padding: 5px;
          }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__home:hover, .IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu:hover {
            background: linear-gradient(90deg, #4c899d 20%, #235066);
          }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__home {
          display: none;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu {
          display: initial;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .sign-in {
          display: initial;
          margin-right: 20px;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .sign-in .button.secondary {
            background: none;
            box-shadow: none;
            font-weight: 600 !important;
            text-decoration: underline;
            padding: 0;
          }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .sign-in .button.secondary span {
              display: inline;
            }

.IndexContainerPnp .main-content-container .brand-description img {
        width: 250px;
      }

.IndexContainerPnp .main-content-container .brand-description h2 {
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
      }

.IndexContainerPnp .main-content-container .Banners {
      background-color: #03072e;
    }

.IndexContainerPnp .main-content-container .Banners video.background {
        opacity: 0.5;
      }

@media (max-width: 530px) {
        .IndexContainerPnp .main-content-container .brand-description img {
          width: 200px;
        }
  }

.IndexContainerPnp .seo-body .seo-body-content {
      width: 80%;
      margin: 0 auto;
    }

@media (max-width: 992px) {

.IndexContainerPnp .seo-body .seo-body-content {
        width: 100%
    }
      }

.IndexContainerPnp .index-container-full .seo-header {
      width: 100%;
    }

.IndexContainerPnp .index-container-full .seo-header .seo-header-content {
        padding: 0 15px 0 15px;
      }

.IndexContainerPnp .index-container-full.seo-header {
      padding-bottom: 155px;
    }

@media (max-width: 380px) {

.IndexContainerPnp .index-container-full.seo-header {
        padding-bottom: 190px
    }
      }

body[data-pnp-step] .index__header__logo {
  display: block !important;
}

.Dashboard .balance {

    padding-bottom: 0;
  }

.Dashboard .balance > div {
      display: flex;
      flex-direction: column-reverse;
      font-style: italic;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 20px;
    }

.Dashboard .balance > div span {
        font-style: normal;
      }

.Dashboard .ItemWithIconAndText,
  .Dashboard .support .button.primary {
    background: none;
    border-radius: 4px;
    box-shadow: none;
    background-color: #2579ad;
    display: flex;
    flex-flow: wrap;
    margin: 0;
    width: 100%;
    margin-bottom: 1px;
    padding: 10px;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 16px;
    line-height: initial;
    min-height: 70px;
    font-weight: 800;
  }

.Dashboard .ItemWithIconAndText .text, .Dashboard .support .button.primary .text {
      margin-top: 0;
      margin-left: 20px;
    }

.Dashboard .ItemWithIconAndText:after, .Dashboard .support .button.primary:after {
      content: '';
      position: absolute;
      right: 30px;
      height: 17px;
      width: 9px;
      background-image: url('../images/menu-arrow.svg');
    }

.Dashboard .ItemWithIconAndText .icon, .Dashboard .support .button.primary .icon {
      width: 35px;
      height: 35px;
    }

.Dashboard .products {
    flex-flow: column;
    order: 1;
    margin-bottom: 20px;
  }

.Dashboard .profile-links {
    order: 2;
  }

.Dashboard .products a, .Dashboard .profile-links a {
      border-radius: 0;
    }

.Dashboard .products a:first-child, .Dashboard .profile-links a:first-child {
      border-radius: 4px 4px 0 0;
    }

.Dashboard .products a:last-child, .Dashboard .profile-links a:last-child {
      border-radius: 0 0 4px 4px;
    }

.Dashboard .products a:only-child, .Dashboard .profile-links a:only-child {
      border-radius: 4px;
    }

.Dashboard .support {
    padding: 0;
    order: 3;
    margin-top: 20px;
  }

.Dashboard .support .button.primary {
      position: relative;
    }

.Dashboard .support .button.primary:after {
        top: 25px;
        right: 15px;
      }

.Dashboard .support span {
      line-height: 50px;
      margin-top: 0;
      margin-left: 20px;
      padding-left: 34px;
      position: relative;
    }

.Dashboard .support span:before {
        position: absolute;
        left: -22px;
        top: 8px;
        content: '';
        width: 35px;
        height: 35px;
        background-image: url('../images/support.svg');
        background-size: contain;
      }

.Dashboard .navigation {
    order: 4;
    display: flex;
    flex-flow: column;
    font-size: 1.2em;
    margin-top: 20px;
    line-height: 45px;
    padding-left: 10px;
  }

.Dashboard .navigation a {
      font-weight: 800;
      text-decoration: none;
    }

.Dashboard .payment-buttons {
    padding-bottom: 0;
    margin-top: 50px;
    order: 5;
  }

.Dashboard .payment-buttons .button {
      margin: 0 5px;
    }

.Dashboard .footer {
    position: relative;
    order: 6;
    flex-grow: 1;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 30px 0 0 0;
  }

.Dashboard .footer a.button.tertiary {
      margin-right: 5px;
      background: none;
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;
      font-weight: normal !important;
      text-transform: initial;
      text-decoration: underline;
      line-height: unset;
      display: flex;
      padding: 10px;
    }

.BasePage {
  font-style: italic;
}

.BasePage .main-content-container {
    background-image: linear-gradient(
        transparent 0%,
        #03072e 100%
      ),
      url('../images/start-page-bg.jpg');

    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
  }

.toast .toast-content {
    padding: 5px 50px;
  }

.CasinoContainer .main-content-container .content {
      padding-top: 20px;
    }

.CasinoContainer .main-content-container .Banners {
      height: 35vh;
    }

@media (max-width: 992px) {

.CasinoContainer .main-content-container .Banners {
        height: 35vh
    }
      }

@media (max-width: 750px) {

.CasinoContainer .main-content-container .Banners {
        height: 35vh
    }

        .CasinoContainer .main-content-container .Banners .background {
          background-position: left !important;
        }
      }

.CasinoContainer .main-content-container .Banners .content {
        bottom: 0 !important;
        left: 0 !important;
        top: initial;
        right: initial;
        padding: 0 50px 30px;
        font-weight: 400;
      }

.CasinoContainer .main-content-container .CasinoGrid {
      margin: 20px 33px;
      padding: 0 20px;
    }

.CasinoContainer .main-content-container .CasinoGrid:first-child {
        margin-top: 0;
      }

.CasinoContainer .main-content-container .CasinoGrid__header {
        justify-content: unset;
        align-items: center;
        margin-bottom: 10px;
      }

.CasinoContainer .main-content-container .CasinoGrid__header .header-and-icon {
          font-size: 1.4em;
        }

.CasinoContainer .main-content-container .CasinoGrid__header a {
          margin: 0 20px;
        }

.CasinoContainer .main-content-container .CasinoGrid__item .Game .game-info {
            position: absolute;
          }

@media (max-width: 992px) {

.CasinoContainer .main-content-container .CasinoGrid {
        padding: 0 10px;
        margin: 0
    }
      }

.ContentContainer .actual-content {

    margin-left: 22px;
    margin-right: 10px;
  }

.ContentContainer .actual-content h1,
    .ContentContainer .actual-content h2,
    .ContentContainer .actual-content h3,
    .ContentContainer .actual-content h4,
    .ContentContainer .actual-content h5,
    .ContentContainer .actual-content h6 {
      color: #f8f8f8 !important;
    }

.ContentContainer .main-content-container[style] {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

.ContentContainer main.container .content-container {
      padding: 20px 6% 0;
    }

.TermsContainer .actual-content {
    margin-left: 32px;
    margin-right: 10px;
  }

.responsible-gaming header p {
      margin-top: 0;
      margin-bottom: 20px;
    }

.newsletter-container .newsletter-buttons {
    flex-direction: column;
    display: flex;
    margin: 0 auto;
  }

.newsletter-container .newsletter-buttons .subscribe-item {
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 25px;
    }

.newsletter-container .newsletter-buttons .subscribe-item label {
        text-transform: inherit;
        width: 235px;
      }

.newsletter-container .newsletter-buttons .subscribe-item:last-child {
        margin-bottom: 0;
      }

.Activate .form {
    margin: 0 auto;
  }

.Activate button {
    background: linear-gradient(to right, #5fa9c1 0%, #31708e 100%);
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: #f8f8f8;
    transition: none;
  }

.Activate button:hover {
      background: linear-gradient(90deg, #4c899d 20%, #235066);
      color: #f8f8f8;
    }

.Activate .resend {
    text-decoration: underline;
  }

.SourceOfWealthContainer .other textarea {
      border: 1px solid #1e638e;
      background: #2579ad;
      color: #f8f8f8;
      border-radius: var(--boder-radius);
      font-size: 14px;
      font-style: italic;
    }

.SourceOfWealthContainer .other textarea::-webkit-input-placeholder {
        color: #f8f8f8;
      }

.SourceOfWealthContainer .other textarea::placeholder {
        color: #f8f8f8;
      }

.SourceOfWealthContainer .alternatives .tg-input .Input,
      .SourceOfWealthContainer .alternatives .tg-input .Select,
      .SourceOfWealthContainer .alternatives .tg-select .Input,
      .SourceOfWealthContainer .alternatives .tg-select .Select {
        border: 1px solid #2579ad;
        padding: 3px;
        border-radius: 50px;
      }

@media (max-width: 992px) {
    .SourceOfWealthContainer .button-wrapper {
      flex-direction: column;
    }

      .SourceOfWealthContainer .button-wrapper .button {
        width: 100%;
        margin: 0;
      }

        .SourceOfWealthContainer .button-wrapper .button:nth-child(2) {
          margin-top: 10px;
        }
  }

.SourceOfWealthContainer hr {
    border: none;
    height: 1px;
    background: #f8f8f8;
  }

.WithdrawContainer .balance-info {
    text-transform: uppercase;
  }

.SportsContainer {
  background: #00020a;
}

@media (max-width: 992px) {
    .SportsContainer .BetslipTab {
      -webkit-transform: translate(-50%, 0%);
              transform: translate(-50%, 0%);
      text-decoration: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      max-width: calc(100% - 100px);
      bottom: calc(env(safe-area-inset-bottom) + 69px);
      left: 50%;
    }

      .SportsContainer .BetslipTab > .icon:first-child {
        padding-top: 9px;
      }
  }

.EventCalendarContainer .main-content-container {
    background-size: cover;
    background-position: center;
  }

.EventCalendarContainer .Footer {
    position: relative;
  }

/* .main-content-container,
  .main-content-container > .container {
    min-height: calc(100vh - var(--header-height));

    &:before,
    &:after {
      z-index: -1;
      content: '';
      position: fixed;
      top: -3000%;
      bottom: 0;
      right: 0;
      left: 0;
      background-image: url(../images/snow.png);
      background-size: cover;
      animation: move-bg 500s linear infinite;
    }
  }

  .main-content-container > .container {
    &:before {
      filter: blur(4px);
      opacity: 0.8;
      top: -2500%;
      background-size: 60%;
      animation-duration: 400s;
    }
    
    &:after {
      filter: blur(2px);
      opacity: 0.8;
      top: -2000%;
      background-image: url(../images/snow.png);
      background-size: 70%;
      animation-duration: 300s;
    }
  } */

.EventCalendarContainer .event-calendar-content {
    padding: 10px;
  }

.EventCalendarContainer .Calendar,
  .EventCalendarContainer .Question {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

.EventCalendarContainer .Calendar > div > p > img {
      height: 150px;
      max-width: 100%;
    }

.EventCalendarContainer .Calendar .calendar-days {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 5px;
      gap: 5px;
    }

.EventCalendarContainer .Calendar .calendar-days .calendar-day {
        border-radius: 1.5em 0.5em 1.5em 0.5em;
        border: 4px solid #005c92;
        height: 250px;
      }

.EventCalendarContainer .Calendar .calendar-days .calendar-day.isBeforeToday {
          opacity: 0.7;
        }

.EventCalendarContainer .Calendar .calendar-days .calendar-day .index,
        .EventCalendarContainer .Calendar .calendar-days .calendar-day .header {
          background-color: #005c92;
          font-weight: bold;
          position: absolute;
        }

.EventCalendarContainer .Calendar .calendar-days .calendar-day .index {
          top: 0;
          left: 0;
          border-radius: 0 0 1.5em 0;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

.EventCalendarContainer .Calendar .calendar-days .calendar-day .header {
          border-radius: 1em 1em 0 0;
          padding: 4px 10px 0 10px;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 80%;
          text-align: center;
        }

@media (min-width: 993px) {

.EventCalendarContainer .Calendar .calendar-days {
        grid-template-columns: repeat(5, 1fr)
    }
      }

.EventCalendarContainer .Question {
    position: relative;
    max-width: 400px;
    background: #005c92;
    border-radius: 1.5em;
    margin: 0 auto;
    padding: 10px;
  }

.EventCalendarContainer .Question .question-close {
      position: absolute;
      top: 10px;
      left: 10px;
      border-radius: 0 0 1em 0;
      width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #005c92;
      padding-bottom: 5px;
      padding-right: 5px;
    }

.EventCalendarContainer .Question .question-close i {
        display: none;
      }

.EventCalendarContainer .Question .question-close:before {
        position: absolute;
        content: '';
        background-image: url('../images/search-close.svg');
        width: 15px;
        height: 15px;
      }

.EventCalendarContainer .Question .question-image {
      width: 100%;
      height: 150px;
      border-radius: 1.5em;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

.EventCalendarContainer .Question .question-extra-info {
      font-size: 0.8em;
    }

@-webkit-keyframes move-bg {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
  }
}

@keyframes move-bg {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
  }
}
