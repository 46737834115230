@import './variables.css';
@import './colors.css';

.casino-promotion {
  .casino-promotion-image,
  .casino-promotion-video video {
    box-shadow: var(--box-shadow);
    border-radius: 6px;
  }

  .stats-wrapper,
  .summary p {
    font-weight: 600;
  }

  .summary {
    img {
      height: 100px;
    }
  }

  .actions.button.primary {
    background: none;
    box-shadow: none;
    border-radius: 0;
    font-size: 1.5em;
    padding: 0;

    &:before {
      content: '';
      background-image: url('../images/play-now.svg');
      width: 40px;
      height: 40px;
      box-shadow: var(--box-shadow);
      border-radius: 50%;
      margin-right: 15px;
    }
  }

  .casino-promotion-bg {
    &::before {
      background: linear-gradient(
        to bottom,
        var(--main-background),
        transparent
      );
    }

    &::after {
      background: linear-gradient(to top, var(--main-background), transparent);
    }
  }

  @media (max-width: 992px) {
    .stats-wrapper {
      display: none;
    }

    .summary p,
    a.actions {
      margin: 0;
    }

    .casino-promotion-content {
      flex-direction: column-reverse;
    }
  }
}
