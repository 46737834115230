@import './colors.css';
@import './variables.css';

.OffCanvas {
  .canvas-overlay {
    background: var(--overlay);
  }

  .canvas-content {
    background: var(--primary-color);

    @media (max-width: 992px) {
      padding: 20px 20px 100px;
    }

    header {
      text-align: center;
      margin-bottom: 25px;

      h1 {
        text-transform: uppercase;
        margin: 0;
      }

      p {
        font-weight: 600;
        margin: 0;
      }
    }

    .canvas-header {
      .sidebar-title {
        display: flex;
      }

      .canvas-header-content {
        width: 100px;
        display: block;
        text-align: center;
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        img {
          width: 100px;
        }
      }
    }

    .canvas-close,
    .canvas-back {
      padding: 7px;
      border-radius: 10px;
      box-shadow: var(--box-shadow);
      background: var(--primary-btn-gradient);
      height: 40px;
      width: 40px;
      position: relative;

      &:empty {
        visibility: hidden;
      }

      img.back-sidebar-icon {
        height: 40px;
        width: 40px;
        position: absolute;
        top: 0;
        left: 0;
      }

      img.exit-sidebar-icon {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px;
      }

      &:hover {
        background: var(--primary-btn-background-hover);
      }
    }
  }
}

@media (max-width: 992px) {
  .DashboardContainer.OffCanvas.OffCanvas--direction-right {
    height: 100%;
  }
}
