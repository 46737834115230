.PayNPlay {
  &.contact, &.bonus {
    max-width: 350px;
  }

  .Input,
  .Select {
    &__trailing-lane {
      display: none;
    }

    &__label {
      font-weight: bold;
      transform: none;
      color: var(--white);
    }

    &--has-value {
      .Input__label {
        display: none;
      }
    }
  }

  .Select {
    &__label {
      display: none;
    }
  }

  .tg-input,
  .tg-phonenumber-input {
    &__status-text {
      font-weight: 600;
    }
  }

  input,
  select {
    font-family: Avenir Next;
    font-weight: bold;
    background: none;
    color: var(--white);
    border-bottom: 2px solid var(--white);
    caret-color: var(--white);
    border-radius: 0;
    padding: 0;

    &::placeholder {
      color: var(--white) !important;
    }
  }

  .Amount {
    &__custom-amount {
      input {
        font-size: 3.5em !important;
        line-height: 1em;
        text-align: center;
      }
    }

    &__question {
      button {
        line-height: inherit;
        background: none;
        box-shadow: none;
        font-size: inherit;
        font-weight: inherit !important;
        text-transform: unset;
        padding: 0;
        text-decoration: underline;
        font-style: italic;

        &:hover {
          background: none;
        }
      }
    }
  }

  .Contact {
    .Input {
      &__label {
        left: 45px;
        top: 20px;
      }
    }

    input[type='email'],
    input[type='tel'] {
      background-repeat: no-repeat;
      padding-left: 45px;
      background-size: 35px 35px;
    }

    input[type='email'] {
      background-image: url('../images/input-email.svg');
      background-position-y: 10px;
    }

    input[type='tel'] {
      background-image: url('../images/input-phone.svg');
      background-position-y: center;
    }
  }

  .Bonus {
    .BonusSelector {
      margin-bottom: var(--pnp-spacing);

      h3 {
        margin: 45px 0 5px 0;
      }

      &__bonus {
        position: relative;
        padding: 15px;
        border-radius: 10px;
        margin-top: 50px;
        background-color: #0182be;

        &__content {
          text-align: left;

          * {
            margin: 0;
          }

          img {
            border-radius: 10px;
            width: 80px;
            height: 80px;
            object-position: center;
            object-fit: cover;
            position: absolute;
            border: 2px solid #ffffff;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: -30px;
          }
        }

        button {
          margin-top: var(--pnp-spacing);
        }
      }
    }
  }
}

.BonusSelector {
  max-width: 320px;
  width: 100%;

  h3 {
    margin: 45px 0 5px 0;
  }

  @media (max-width: 992px) {
    max-width: none;
  }

  &__bonus {
    position: relative;
    padding: 15px;
    border-radius: 10px;
    margin-top: 50px;

    &--selected {
      background-color: #0182BE;
    }
  }

  &__bonus-description {
    text-align: left;

    * {
      margin: 0;
    }

    img {
      border-radius: 10px;
      width: 80px;
      height: 80px;
      object-position: center;
      object-fit: cover;
      position: absolute;
      border: 2px solid #ffffff;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: -30px;
    }
  }
}
