@import './colors';
@import './variables.css';

.header-wrapper {
  background: linear-gradient(#010e27, #0d3659);
  box-shadow: var(--box-shadow);

  .Header__background.Header__background--visible {
    display: none;
  }

  .Header {
    &::before {
      content: '';
      position: absolute;
      left: -10px;
      width: 160px;
      height: 100%;
      clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
      background: rgba(var(--white), 0.1);
      pointer-events: none;
    }
  }

  .Header__logo {
    img {
      height: 35px;
      width: auto;
    }
  }

  .MembershipActions {
    .button {
      &:not(.user-balance) {
        font-size: 1em;
        padding: 0 3em;
        height: 40px;
        line-height: 40px;
        margin: 0;
      }
    }

    .user-balance {
      flex-flow: row-reverse;
      padding-right: 10px !important;
      padding-left: 0 !important;
      margin: 0;

      .icon-eye-opened,
      .icon-eye-closed,
      .dots {
        display: none;
      }

      .balance-hide {
        height: auto;
        opacity: 1;
      }

      .icon-plus {
        position: relative;
        width: 21px;
        height: 21px;
        color: #2c8dc6;
        background: var(--white);
        border-radius: 50%;
        margin-left: 10px;
        line-height: 25px;

        &:before {
          position: absolute;
        }
      }
    }
  }

  .Header__support button,
  .hamburger-icon {
    background: var(--primary-btn-gradient);
    box-shadow: var(--box-shadow);
  }

  .Header__support {
    span {
      display: none;
    }
  }

  .Header__topnav {
    height: 100%;
    display: flex;
    align-items: center;

    .top-nav-menu-item {
      text-transform: uppercase;
      font-size: 17px !important;
      font-weight: 800;
      letter-spacing: 1px;
      position: relative;

      &.active {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 100%;
          background: var(--white);
        }
      }
    }
  }

  .user-balance {
    height: 40px;
  }
  > .Header .Header__support {
    margin-right: 0;
  }

  .Header__support a {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
    margin-right: 15px !important;
    padding: 0;
    background: var(--primary-gradient);
    display: block;

    i {
      &:before {
        content: '';
        width: 30px;
        height: 31px;
        background-image: url('../images/support-small.svg');
        position: absolute;
        background-size: 30px 31px;
        background-repeat: no-repeat;
        top: 5px;
        left: 5px;
      }
    }

    &:hover {
      background: var(--primary-btn-background-hover);
      opacity: 1 !important;
    }
  }

  .hamburger-icon {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    margin-left: 20px !important;
    margin-right: 10px !important;

    img {
      height: 16px !important;
    }

    &:hover {
      background: var(--primary-btn-background-hover);
    }
  }
}
