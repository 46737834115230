.notifications-badge,
.notifications-badge--right {
  background-color: var(--notification-circle-background) !important;
  color: var(--white) !important;
  width: 24px !important;
  height: 24px !important;
  line-height: 25px !important;
}

.DashboardContainer
  .canvas-content
  .Dashboard
  .profile-links
  .ItemWithIconAndText
  .notifications-badge--right {
  box-shadow: none;
  top: 16px;
  right: 18px;
}
