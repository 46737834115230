.Popup {
  &__header {
    color: var(--white);
  }

  &__content {
    overflow: auto;
    font-style: italic;
    color: var(--black);
    border-radius: var(--border-radius);
  }

  &__children {
    .RealityCheckContainer {
      .actions {
        .button {
          &:last-child {
            width: 100%;
            margin: 15px 0;
          }
        }
      }
    }
  }
}
