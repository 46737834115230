@import '../colors.css';
@import '../variables.css';

.Dashboard {
  .balance {
    > div {
      display: flex;
      flex-direction: column-reverse;
      font-style: italic;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 20px;

      span {
        font-style: normal;
      }
    }

    padding-bottom: 0;
  }

  .ItemWithIconAndText,
  .support .button.primary {
    background: none;
    border-radius: var(--border-radius);
    box-shadow: none;
    background-color: var(--secondary-color);
    display: flex;
    flex-flow: wrap;
    margin: 0;
    width: 100%;
    margin-bottom: 1px;
    padding: 10px;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 16px;
    line-height: initial;
    min-height: 70px;
    font-weight: 800;

    .text {
      margin-top: 0;
      margin-left: 20px;
    }

    &:after {
      content: '';
      position: absolute;
      right: 30px;
      height: 17px;
      width: 9px;
      background-image: url('../images/menu-arrow.svg');
    }

    .icon {
      width: 35px;
      height: 35px;
    }
  }

  .products {
    flex-flow: column;
    order: 1;
    margin-bottom: 20px;
  }

  .profile-links {
    order: 2;
  }

  .products,
  .profile-links {
    a {
      border-radius: 0;
    }

    a:first-child {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    a:last-child {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }

    a:only-child {
      border-radius: var(--border-radius);
    }
  }

  .support {
    padding: 0;
    order: 3;
    margin-top: 20px;

    .button.primary {
      position: relative;

      &:after {
        top: 25px;
        right: 15px;
      }
    }

    span {
      line-height: 50px;
      margin-top: 0;
      margin-left: 20px;
      padding-left: 34px;
      position: relative;

      &:before {
        position: absolute;
        left: -22px;
        top: 8px;
        content: '';
        width: 35px;
        height: 35px;
        background-image: url('../images/support.svg');
        background-size: contain;
      }
    }
  }

  .navigation {
    order: 4;
    display: flex;
    flex-flow: column;
    font-size: 1.2em;
    margin-top: 20px;
    line-height: 45px;
    padding-left: 10px;

    a {
      font-weight: 800;
      text-decoration: none;
    }
  }

  .payment-buttons {
    padding-bottom: 0;
    margin-top: 50px;
    order: 5;

    .button {
      margin: 0 5px;
    }
  }

  .footer {
    position: relative;
    order: 6;
    flex-grow: 1;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 30px 0 0 0;

    a.button.tertiary {
      margin-right: 5px;
      background: none;
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;
      font-weight: normal !important;
      text-transform: initial;
      text-decoration: underline;
      line-height: unset;
      display: flex;
      padding: 10px;
    }
  }
}
