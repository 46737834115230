@import './colors.css';

.StickyFooter-mobile {
  background: var(--white);
  width: 295px;
  max-width: calc(100% - 20px);
  left: 50%;
  transform: translateX(-50%);
  bottom: env(safe-area-inset-bottom);
  border-radius: 6px;
  margin: 15px auto;

  .stickyfooter-nav-items {
    overflow-y: inherit;
    overflow-x: inherit;
  }

  a {
    justify-content: center;
    font-weight: 600;
    color: var(--black);

    .icon {
      height: 26px;
      width: 26px;
    }

    img {
      pointer-events: none;
      filter: invert(1);

      & + div {
        font-size: 10px;
      }
    }

    &.exit-sidebar-icon,
    &.hamburger-icon {
      img {
        height: 26px;
        width: 26px;
      }
    }

    &.active {
      background: #2579ad;
      color: var(--white);

      img {
        filter: invert(0);
      }

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  .notifications-badge--right[data-notifications]:not([data-notifications='']):not([data-notifications='0'])::after {
    top: 8px;
    right: 8px;
  }
}
