.BasePage {
  font-style: italic;

  .main-content-container {
    background-image: linear-gradient(
        transparent 0%,
        var(--main-background) 100%
      ),
      var(--background-image);

    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
  }
}

.toast {
  .toast-content {
    padding: 5px 50px;
  }
}
