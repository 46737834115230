h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 800 !important;
}

body {
  &.is-offcanvas-open {
    #fc_frame:not(.fc-open) {
      display: none;
    }
  }
}

#fc_frame:not(.fc-open) {
  display: initial;

  @media (max-width: 768px) {
    display: none;
  }
}

#fasttrack-crm .show-message-button {
  background-color: var(--primary-btn-background) !important;
  color: white !important;
}
