@import '../colors.css';

.newsletter-container {
  .newsletter-buttons {
    flex-direction: column;
    display: flex;
    margin: 0 auto;

    .subscribe-item {
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 25px;

      label {
        text-transform: inherit;
        width: 235px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
