@font-face {
  font-family: Avenir Next;
  font-style: italic;
  font-weight: 400;
  src: url('/skin/fonts/AvenirNextLTPro-It.otf') format('truetype');
}

@font-face {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  src: url('/skin/fonts/AvenirNextLTPro-Regular.otf') format('truetype');
}

@font-face {
  font-family: Avenir Next;
  font-style: italic;
  font-weight: 500;
  src: url('/skin/fonts/AvenirNextLTPro-MediumIt.otf') format('truetype');
}

@font-face {
  font-family: Avenir Next;
  src: url('/skin/fonts/AvenirNextLTPro-DemiIt.otf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Avenir Next;
  src: url('/skin/fonts/AvenirNextLTPro-HeavyIt.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Avenir Next;
  src: url('/skin/fonts/AvenirNextLTPro-Heavy.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Avenir Next;
  src: url('/skin/fonts/AvenirNextLTPro-HeavyIt.otf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Avenir Next;
  src: url('/skin/fonts/AvenirNextLTPro-Heavy.otf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

.OffCanvas,
.WelcomeBonus,
.content h3,
body,
.button,
button,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
nav.categories a,
.casino-categories-item,
.casino-categories-item-total,
.tg-input .Input input,
.tg-select .Select select,
.Promotion .content h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Avenir Next', 'Fira Sans', sans-serif;
}
