@import './colors.css';
@import './variables.css';

input,
select {
  border: none;
  border-radius: var(--border-radius);
  background: var(--white);
  color: black;

  &::placeholder {
    color: #474747;
  }
}

.Input,
.Select {
  label {
    color: var(--black);
  }

  &__status-line {
    display: none;
  }
}

input[type='file'] {
  background: none;
  color: inherit;
}

input[type='radio'] {
  border: 2px solid var(--tertiary-color);
}

input[type='radio']:checked {
  border: 5px solid var(--tertiary-color);
  background: var(--white);
}
