.notification {
  background: var(--secondary-color);
  border-radius: 0 0 var(--border-radius) var(--border-radius);

  .timeout-bar {
    background: var(--white);
  }

  .content .head {
    .date {
      margin-top: 2px;
    }
  }

  .notification-buttons {
    .notification-button {
      color: var(--white);
      border-top: 1px solid var(--white);

      &:hover {
        color: rgba(var(--white), 0.8);
      }
    }
  }
}
