.JackpotDropContainer {
  main > header {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: 993px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .JackpotWidget {
    text-shadow: var(--text-shadow);

    &__badge {
      font-weight: bold;
      border-radius: 2px;
      text-shadow: initial;
    }

    &__info {
      &::before {
        content: '';
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        margin-right: 20px;
      }

      &--clock::before {
        background-image: url('../images/clock-icon.svg');
      }

      &--jackpot::before {
        background-image: url('../images/diamond-icon.svg');
      }
    }
  }
}
